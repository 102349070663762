:root {
    --mm-lineheight: 22px;

    --mm-size: calc(100% - 55px);
    --mm-min-size: 240px;
    --mm-max-size: 440px;
    --mm-blocker-visibility-delay: 0.4s;
    --mm-blocker-opacity-delay: 0s;
    --mm-sidebar-collapsed-size: 50px;
    --mm-sidebar-expanded-size: var(--mm-max-size);
    --mm-sectionindexer-size: 20px;
    --mm-iconpanel-size: 50px;
    --mm-iconbar-size: 50px;
    --mm-toggle-size: 34px;
    --mm-listitem-size: 50px;
    --mm-navbar-size: 50px;
}
.mm-hidden {
    display: none !important;
}
.mm-wrapper {
    overflow-x: hidden;
    position: relative;
}
.mm-menu,
.mm-menu *,
.mm-menu :after,
.mm-menu :before {
    -webkit-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}

.mm-blocker {
    display: block;
    position: absolute;
    bottom: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    background: var(--mm-color-background);
    -webkit-transition: bottom 0s ease var(--mm-blocker-visibility-delay), width 0.4s ease, opacity 0.4s ease var(--mm-blocker-opacity-delay), -webkit-transform 0.4s ease;
    transition: bottom 0s ease var(--mm-blocker-visibility-delay), width 0.4s ease, opacity 0.4s ease var(--mm-blocker-opacity-delay), -webkit-transform 0.4s ease;
    -o-transition: bottom 0s ease var(--mm-blocker-visibility-delay), width 0.4s ease, opacity 0.4s ease var(--mm-blocker-opacity-delay), transform 0.4s ease;
    transition: bottom 0s ease var(--mm-blocker-visibility-delay), width 0.4s ease, opacity 0.4s ease var(--mm-blocker-opacity-delay), transform 0.4s ease;
    transition: bottom 0s ease var(--mm-blocker-visibility-delay), width 0.4s ease, opacity 0.4s ease var(--mm-blocker-opacity-delay), transform 0.4s ease, -webkit-transform 0.4s ease;
}
.mm-blocker:focus-visible {
    opacity: 0.75;
}
.mm-btn {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    width: 50px;
    padding: 0;
}
.mm-btn--next,
[dir="rtl"] .mm-btn--prev {
    --mm-btn-rotate: 135deg;
}
.mm-btn--prev,
[dir="rtl"] .mm-btn--next {
    --mm-btn-rotate: -45deg;
}
.mm-btn--next:after,
.mm-btn--prev:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1em;
    width: 8px;
    height: 8px;
    margin: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid var(--mm-color-icon);
    border-bottom: none;
    border-right: none;
    -webkit-transform: rotate(var(--mm-btn-rotate));
    -ms-transform: rotate(var(--mm-btn-rotate));
    transform: rotate(var(--mm-btn-rotate));
}
.mm-btn--prev:before {
    inset-inline-start: 23px;
}
.mm-btn--next:after {
    inset-inline-end: 23px;
}
.mm-btn--close:before {
    content: "×";
    font-size: 150%;
}
.mm-btnreset {
    padding: 0;
    background: 0 0;
    border: none;
    cursor: pointer;
}
.mm-divider {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 0;
    min-height: var(--mm-lineheight);
    padding: calc((var(--mm-listitem-size) * 0.65 - var(--mm-lineheight)) * 0.5) 20px;
    font-size: 75%;
    text-transform: uppercase;
    background: var(--mm-color-background);
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--mm-color-background-highlight)), to(var(--mm-color-background-highlight)));
    background-image: -o-linear-gradient(var(--mm-color-background-highlight), var(--mm-color-background-highlight));
    background-image: linear-gradient(var(--mm-color-background-highlight), var(--mm-color-background-highlight));
    opacity: 1;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}
.mm-navbar:not(.mm-hidden) ~ .mm-listview .mm-divider {
    top: var(--mm-navbar-size);
}

.mm-listitem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    padding: 0;
    margin: 0;
    color: var(--mm-color-text);
    border-color: var(--mm-color-border);
}
.mm-listitem:after {
    content: "";
    border-color: inherit;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    position: absolute;
    inset-inline-start: 20px;
    inset-inline-end: 0;
    bottom: 0;
    display: none;
}
.mm-listitem__btn,
.mm-listitem__text {
    padding: calc((var(--mm-listitem-size) - var(--mm-lineheight)) / 2) 0;
}
.mm-listitem__text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 10%;
    flex-basis: 10%;
    display: block;
    padding-left: 40px;
    padding-right: 20px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size:1.25em;
    font-weight:600;
    &.secundario {
        font-size:1em;
        font-weight:500;
        padding-top:.25em;
        padding-bottom:.25em;
    }
}
.mm-listitem__btn {
    font-weight:600;
    font-size:1.25em;
    color: #777 !important;
    display: block;
    position: relative;
    width: auto;
    padding-inline-end: 90px;
    border-color: inherit;
    background: rgba(3, 2, 1, 0);
}
.mm-listitem__btn:not(.mm-listitem__text) {
    border-left-width: 0;
    border-left-style: solid;
}
.mm-listitem--selected > .mm-listitem__text {
    background: var(--mm-color-background-emphasis);
}
.mm-listitem--opened > .mm-listitem__btn,
.mm-listitem--opened > .mm-panel {
    background: var(--mm-color-background-highlight);
}
.mm-listview {
    list-style: none;
    display: block;
    padding: 0;
    margin: 1rem 0 0 0;
}
.mm-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    border-color: var(--mm-color-border);
    color: var(--mm-color-text);
    line-height: var(--mm-lineheight);
    -webkit-tap-highlight-color: var(--mm-color-background-emphasis);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: var(--mm-color-background) url(../images/logo.svg) no-repeat center .5rem  / 25%;
}
.mm-menu ::-webkit-input-placeholder {
    color: var(--mm-color-text-dimmed);
}
.mm-menu ::-moz-placeholder {
    color: var(--mm-color-text-dimmed);
}
.mm-menu :-ms-input-placeholder {
    color: var(--mm-color-text-dimmed);
}
.mm-menu ::-ms-input-placeholder {
    color: var(--mm-color-text-dimmed);
}
.mm-menu ::placeholder {
    color: var(--mm-color-text-dimmed);
}
.mm-menu,
.mm-menu * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.mm-menu :focus,
.mm-menu :focus-visible,
.mm-menu:focus,
.mm-menu:focus-visible {
    outline: 0;
}
.mm-menu a,
.mm-menu button,
.mm-menu label {
    color: inherit;
}
.mm-menu a:focus,
.mm-menu button:focus,
.mm-menu label:focus {
    outline: 0;
}
.mm-menu a:focus-visible,
.mm-menu button:focus-visible,
.mm-menu label:focus-visible {
    outline: 2px solid var(--mm-color-focusring);
    outline-offset: -5px;
}
.mm-menu input:focus,
.mm-menu input:focus-visible,
.mm-menu select:focus,
.mm-menu select:focus-visible,
.mm-menu textarea:focus,
.mm-menu textarea:focus-visible {
    outline: 2px solid var(--mm-color-focusring);
    outline-offset: 2px;
}
.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
    text-decoration: none;
    color: inherit;
}

.mm-navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    min-height: var(--mm-navbar-size);
    padding-top: env(safe-area-inset-top);
    color: var(--mm-color-text-dimmed);
    text-align: center;
    opacity: 1;
    background: var(--mm-color-background);
    border: 1px solid var(--mm-color-border);
    transition-property: opacity;
}
.mm-navbar > * {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.mm-navbar__btn {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
}
.mm-navbar__title {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 20px;
    overflow: hidden;
}
.mm-navbar__title[href="#"] {
    pointer-events: none;
}
.mm-navbar__title > span {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.mm-btn.mm-hidden + .mm-navbar__title:not(:last-child) {
    -webkit-padding-start: 60px;
    padding-inline-start: 60px;
    -webkit-padding-end: 10px;
    padding-inline-end: 10px;
}
.mm-btn:not(.mm-hidden) + .mm-navbar__title:last-child {
    -webkit-padding-start: 10px;
    padding-inline-start: 10px;
    -webkit-padding-end: 60px;
    padding-inline-end: 60px;
}
.mm-panel {
    --mm-panel-child-offset: 100%;
    --mm-panel-parent-offset: -30%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    color: var(--mm-color-text);
    border-color: var(--mm-color-border);
    background: var(--mm-color-background);
    -webkit-transform: translate3d(var(--mm-panel-child-offset), 0, 0);
    transform: translate3d(var(--mm-panel-child-offset), 0, 0);
    -webkit-transition-property: inset-inline-start, -webkit-transform;
    transition-property: inset-inline-start, -webkit-transform;
    -o-transition-property: transform, inset-inline-start;
    transition-property: transform, inset-inline-start;
    transition-property: transform, inset-inline-start, -webkit-transform;
}
[dir="rtl"] .mm-panel {
    --mm-panel-child-offset: -100%;
    --mm-panel-parent-offset: 30%;
}
.mm-panel:after {
    content: "";
    display: block;
    height: var(--mm-listitem-size);
}
.mm-panel:focus {
    outline: 0;
}
.mm-panel--opened {
    z-index: 2;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}
.mm-panel--parent {
    -webkit-transform: translate3d(var(--mm-panel-parent-offset), 0, 0);
    transform: translate3d(var(--mm-panel-parent-offset), 0, 0);
    z-index: 0;
}
.mm-panel--highest {
    z-index: 3;
}
.mm-menu--opened .mm-panel--noanimation {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    -webkit-transition-duration: 0s !important;
    -o-transition-duration: 0s !important;
    transition-duration: 0s !important;
}
.mm-panel__content {
    padding: 20px;
}
.mm-panels {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
    background: var(--mm-color-background);
    border-color: var(--mm-color-border);
    font-size: 1.25rem;
    color: var(--mm-color-text);
}
.mm-panels:focus {
    outline: 0;
}

.mm-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: calc(var(--mm-toggle-size) * 1.75);
    -ms-flex: calc(var(--mm-toggle-size) * 1.75) 0 0px;
    flex: calc(var(--mm-toggle-size) * 1.75) 0 0;
    height: var(--mm-toggle-size);
    -webkit-margin-end: 10px;
    margin-inline-end: 10px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border: none !important;
    border-radius: var(--mm-toggle-size);
    border: var(--mm-color-border);
    background: var(--mm-color-border);
    cursor: pointer;
    -webkit-transition-property: background-color;
    -o-transition-property: background-color;
    transition-property: background-color;
}
.mm-toggle:before {
    content: "";
    aspect-ratio: 1;
    margin: 2px;
    border-radius: 100%;
    background: var(--mm-color-background);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.mm-toggle:checked {
    background: #4bd963;
}
.mm-toggle:checked:before {
    -webkit-transform: translateX(calc(var(--mm-toggle-size) * 0.75));
    -ms-transform: translateX(calc(var(--mm-toggle-size) * 0.75));
    transform: translateX(calc(var(--mm-toggle-size) * 0.75));
}
[dir="rtl"] .mm-toggle:checked:before {
    -webkit-transform: translateX(calc(var(--mm-toggle-size) * -0.75));
    -ms-transform: translateX(calc(var(--mm-toggle-size) * -0.75));
    transform: translateX(calc(var(--mm-toggle-size) * -0.75));
}
.mm-listitem--vertical > .mm-panel {
    position: static;
    width: 100%;
    padding: 10px 0 10px 20px;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}
.mm-listitem--vertical > .mm-panel:after {
    content: none;
    display: none;
}
.mm-listitem--vertical:not(.mm-listitem--opened) > .mm-panel {
    display: none;
}
.mm-listitem--vertical > .mm-listitem__btn {
    height: var(--mm-listitem-size);
    bottom: auto;
}
.mm-listitem--vertical .mm-listitem:last-child:after {
    border-color: transparent;
}
.mm-listitem--opened > .mm-listitem__btn:after {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    right: 19px;
}

.mm-menu--offcanvas {
    position: fixed;
    z-index: 0;
}
.mm-page {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 100vh;
    background: inherit;
}
:where(.mm-slideout) {
    position: relative;
    z-index: 1;
    width: 100%;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    -o-transition-property: width, transform;
    transition-property: width, transform;
    transition-property: width, transform, -webkit-transform;
}
.mm-wrapper--opened,
.mm-wrapper--opened body {
    overflow: hidden;
}
body.mm-wrapper--opened {
    position: fixed; 
}
.mm-wrapper__blocker {
    background: rgba(0, 0, 0, 0.4);
}
.mm-wrapper--opened .mm-wrapper__blocker {
    --mm-blocker-visibility-delay: 0s;
    --mm-blocker-opacity-delay: 0.4s;
    bottom: 0;
    opacity: 0.75;
}
.mm-menu {
    --mm-translate-horizontal: 0;
    --mm-translate-vertical: 0;
}
.mm-menu--position-left,
.mm-menu--position-left-front {
    right: auto;
}
.mm-menu--position-right,
.mm-menu--position-right-front {
    left: auto;
}
.mm-menu--position-left,
.mm-menu--position-left-front,
.mm-menu--position-right,
.mm-menu--position-right-front {
    width: clamp(var(--mm-min-size), var(--mm-size), var(--mm-max-size));
}
.mm-menu--position-left-front {
    --mm-translate-horizontal: -100%;
}
.mm-menu--position-right-front {
    --mm-translate-horizontal: 100%;
}
.mm-menu--position-top {
    bottom: auto;
}
.mm-menu--position-bottom {
    top: auto;
}
.mm-menu--position-bottom,
.mm-menu--position-top {
    width: 100%;
    height: clamp(var(--mm-min-size), var(--mm-size), var(--mm-max-size));
}
.mm-menu--position-top {
    --mm-translate-vertical: -100%;
}
.mm-menu--position-bottom {
    --mm-translate-vertical: 100%;
}
.mm-menu--position-bottom,
.mm-menu--position-left-front,
.mm-menu--position-right-front,
.mm-menu--position-top {
    z-index: 2;
    -webkit-transform: translate3d(var(--mm-translate-horizontal), var(--mm-translate-vertical), 0);
    transform: translate3d(var(--mm-translate-horizontal), var(--mm-translate-vertical), 0);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.mm-menu--position-bottom.mm-menu--opened,
.mm-menu--position-left-front.mm-menu--opened,
.mm-menu--position-right-front.mm-menu--opened,
.mm-menu--position-top.mm-menu--opened {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.mm-wrapper--position-left {
    --mm-translate-horizontal: clamp(var(--mm-min-size), var(--mm-size), var(--mm-max-size));
    
}
.mm-wrapper--position-right {
    --mm-translate-horizontal: clamp(calc(-1 * var(--mm-max-size)), calc(-1 * var(--mm-size)), calc(-1 * var(--mm-min-size)));
}
.mm-wrapper--position-left .mm-slideout,
.mm-wrapper--position-right .mm-slideout {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.mm-wrapper--position-left.mm-wrapper--opened .mm-slideout,
.mm-wrapper--position-right.mm-wrapper--opened .mm-slideout {
    -webkit-transform: translate3d(var(--mm-translate-horizontal), 0, 0);
    transform: translate3d(var(--mm-translate-horizontal), 0, 0);
}
.mm-wrapper--position-bottom .mm-wrapper__blocker,
.mm-wrapper--position-left-front .mm-wrapper__blocker,
.mm-wrapper--position-right-front .mm-wrapper__blocker,
.mm-wrapper--position-top .mm-wrapper__blocker {
    z-index: 1;
}

.mm-menu--theme-light {
    --mm-color-background: #f3f3f3;
    --mm-color-border: rgb(0 0 0 / 0.15);
    --mm-color-icon: rgb(0 0 0 / 0.4);
    --mm-color-text: rgb(0 0 0 / 0.8);
    --mm-color-text-dimmed: rgb(0 0 0 / 0.4);
    --mm-color-background-highlight: rgb(0 0 0 / 0.05);
    --mm-color-background-emphasis: rgb(255 255 255 / 0.75);
    --mm-color-focusring: #06c;
}
.mm-menu--theme-light-contrast {
    --mm-color-background: #f3f3f3;
    --mm-color-border: rgb(0 0 0 / 0.5);
    --mm-color-icon: rgb(0 0 0 / 0.5);
    --mm-color-text: #000;
    --mm-color-text-dimmed: rgb(0 0 0 / 0.7);
    --mm-color-background-highlight: rgb(0 0 0 / 0.05);
    --mm-color-background-emphasis: rgb(255 255 255 / 0.9);
    --mm-color-focusring: #06c;
}
.mm-menu--theme-dark {
    --mm-color-background: #333;
    --mm-color-border: rgb(0, 0, 0, 0.4);
    --mm-color-icon: rgb(255, 255, 255, 0.4);
    --mm-color-text: rgb(255, 255, 255, 0.8);
    --mm-color-text-dimmed: rgb(255, 255, 255, 0.4);
    --mm-color-background-highlight: rgb(255, 255, 255, 0.08);
    --mm-color-background-emphasis: rgb(0, 0, 0, 0.1);
    --mm-color-focusring: #06c;
}
.mm-menu--theme-dark-contrast {
    --mm-color-background: #333;
    --mm-color-border: rgb(255 255 255 / 0.5);
    --mm-color-icon: rgb(255 255 255 / 0.5);
    --mm-color-text: #fff;
    --mm-color-text-dimmed: rgb(255 255 255 / 0.7);
    --mm-color-background-highlight: rgb(255 255 255 / 0.1);
    --mm-color-background-emphasis: rgb(0 0 0 / 0.3);
    --mm-color-focusring: #06c;
}
.mm-menu--theme-white {
    --mm-color-background: #fff;
    --mm-color-border: rgb(0 0 0 / 0.15);
    --mm-color-icon: rgb(0 0 0 / 0.3);
    --mm-color-text: rgb(0 0 0 / 0.8);
    --mm-color-text-dimmed: rgb(0 0 0 / 0.3);
    --mm-color-background-highlight: rgb(0 0 0 / 0.06);
    --mm-color-background-emphasis: rgb(0 0 0 / 0.03);
    --mm-color-focusring: #06c;
}
.mm-menu--theme-white-contrast {
    --mm-color-background: #fff;
    --mm-color-border: rgb(0 0 0 / 0.5);
    --mm-color-icon: rgb(0 0 0 / 0.5);
    --mm-color-text: #000;
    --mm-color-text-dimmed: rgb(0 0 0 / 0.7);
    --mm-color-background-highlight: rgb(0 0 0 / 0.07);
    --mm-color-background-emphasis: rgb(0 0 0 / 0.035);
    --mm-color-focusring: #06c;
}
.mm-menu--theme-black {
    --mm-color-background: #000;
    --mm-color-border: rgb(255 255 255 / 0.2);
    --mm-color-icon: rgb(255 255 255 / 0.4);
    --mm-color-text: rgb(255 255 255 / 0.7);
    --mm-color-text-dimmed: rgb(255 255 255 / 0.4);
    --mm-color-background-highlight: rgb(255 255 255 / 0.1);
    --mm-color-background-emphasis: rgb(255 255 255 / 0.06);
    --mm-color-focusring: #06c;
}
.mm-menu--theme-black-contrast {
    --mm-color-background: #000;
    --mm-color-border: rgb(255 255 255 / 0.5);
    --mm-color-icon: rgb(255 255 255 / 0.5);
    --mm-color-text: #fff;
    --mm-color-text-dimmed: rgb(255 255 255 / 0.6);
    --mm-color-background-highlight: rgb(255 255 255 / 0.125);
    --mm-color-background-emphasis: rgb(255 255 255 / 0.1);
    --mm-color-focusring: #06c;
}
.mm-counter {
    display: block;
    -webkit-padding-start: 20px;
    padding-inline-start: 20px;
    float: right;
    color: var(--mm-color-text-dimmed);
}
[dir="rtl"] .mm-counter {
    float: left;
}
.mm-menu--iconbar-left .mm-navbars,
.mm-menu--iconbar-left .mm-panels {
    margin-left: var(--mm-iconbar-size);
}
.mm-menu--iconbar-right .mm-navbars,
.mm-menu--iconbar-right .mm-panels {
    margin-right: var(--mm-iconbar-size);
}
.mm-iconbar {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: var(--mm-iconbar-size);
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0 solid;
    border-color: var(--mm-color-border);
    background: var(--mm-color-background);
    color: var(--mm-color-text-dimmed);
    text-align: center;
}
.mm-menu--iconbar-left .mm-iconbar,
.mm-menu--iconbar-right .mm-iconbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.mm-menu--iconbar-left .mm-iconbar {
    border-right-width: 1px;
    left: 0;
}
.mm-menu--iconbar-right .mm-iconbar {
    border-left-width: 1px;
    right: 0;
}
.mm-iconbar__bottom,
.mm-iconbar__top {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
}
.mm-iconbar__bottom > *,
.mm-iconbar__top > * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    padding: calc((var(--mm-iconbar-size) - var(--mm-lineheight)) / 2) 0;
}
.mm-iconbar__bottom a,
.mm-iconbar__bottom a:hover,
.mm-iconbar__top a,
.mm-iconbar__top a:hover {
    text-decoration: none;
}
.mm-iconbar__tab--selected {
    background: var(--mm-color-background-emphasis);
}
.mm-panel--iconpanel-0 {
    inset-inline-start: calc(0 * var(--mm-iconpanel-size));
}
.mm-panel--iconpanel-1 {
    inset-inline-start: calc(1 * var(--mm-iconpanel-size));
}
.mm-panel--iconpanel-2 {
    inset-inline-start: calc(2 * var(--mm-iconpanel-size));
}
.mm-panel--iconpanel-3 {
    inset-inline-start: calc(3 * var(--mm-iconpanel-size));
}
.mm-panel--iconpanel-4 {
    inset-inline-start: calc(4 * var(--mm-iconpanel-size));
}
.mm-panel--iconpanel-first ~ .mm-panel {
    inset-inline-start: var(--mm-iconpanel-size);
}
.mm-menu--iconpanel .mm-panel--parent .mm-divider,
.mm-menu--iconpanel .mm-panel--parent .mm-navbar {
    opacity: 0;
}
.mm-menu--iconpanel .mm-panels > .mm-panel--parent {
    overflow-y: hidden;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
}
.mm-menu--iconpanel .mm-panels > .mm-panel:not(.mm-panel--iconpanel-first):not(.mm-panel--iconpanel-0) {
    border-inline-start-width: 1px;
    border-inline-start-style: solid;
}
.mm-navbars {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.mm-navbars .mm-navbar {
    position: relative;
    padding-top: 0;
    border-bottom: none;
}
.mm-navbars--top {
    border-bottom: 1px solid var(--mm-color-border);
}
.mm-navbars--top .mm-navbar:first-child {
    padding-top: env(safe-area-inset-top);
}
.mm-navbars--bottom {
    border-top: 1px solid var(--mm-color-border);
}
.mm-navbars--bottom .mm-navbar:last-child {
    padding-bottom: env(safe-area-inset-bottom);
}
.mm-navbar__breadcrumbs {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0 20px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.mm-navbar__breadcrumbs > * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-padding-end: 6px;
    padding-inline-end: 6px;
}
.mm-navbar__breadcrumbs > a {
    text-decoration: underline;
}
.mm-navbar__breadcrumbs:not(:last-child) {
    -webkit-padding-end: 0;
    padding-inline-end: 0;
}
.mm-btn:not(.mm-hidden) + .mm-navbar__breadcrumbs {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
}
.mm-navbar__tab {
    padding: 0 10px;
    border: 1px solid transparent;
}
.mm-navbar__tab--selected {
    background: var(--mm-color-background);
}
.mm-navbar__tab--selected:not(:first-child) {
    border-inline-start-color: var(--mm-color-border);
}
.mm-navbar__tab--selected:not(:last-child) {
    border-inline-end-color: var(--mm-color-border);
}
.mm-navbars--top.mm-navbars--has-tabs {
    border-bottom: none;
}
.mm-navbars--top.mm-navbars--has-tabs .mm-navbar {
    background: var(--mm-color-background-emphasis);
}
.mm-navbars--top.mm-navbars--has-tabs .mm-navbar--tabs ~ .mm-navbar {
    background: var(--mm-color-background);
}
.mm-navbars--top.mm-navbars--has-tabs .mm-navbar:not(.mm-navbar--tabs):last-child {
    border-bottom: 1px solid var(--mm-color-border);
}
.mm-navbars--top .mm-navbar__tab {
    border-bottom-color: var(--mm-color-border);
}
.mm-navbars--top .mm-navbar__tab--selected {
    border-top-color: var(--mm-color-border);
    border-bottom-color: transparent;
}
.mm-navbars--bottom.mm-navbar--has-tabs {
    border-top: none;
}
.mm-navbars--bottom.mm-navbar--has-tabs .mm-navbar {
    background: var(--mm-color-background);
}
.mm-navbars--bottom.mm-navbar--has-tabs .mm-navbar--tabs,
.mm-navbars--bottom.mm-navbar--has-tabs .mm-navbar--tabs ~ .mm-navbar {
    background: var(--mm-color-background-emphasis);
}
.mm-navbars--bottom .mm-navbar__tab {
    border-top-color: var(--mm-color-border);
}
.mm-navbars--bottom .mm-navbar__tab--selected {
    border-bottom-color: var(--mm-color-border);
    border-top-color: transparent;
}
.mm-searchfield {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: var(--mm-navbar-size);
    padding: 0;
    overflow: hidden;
}
.mm-searchfield__input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.mm-searchfield__input input {
    display: block;
    width: 100%;
    max-width: 100%;
    height: calc(var(--mm-navbar-size) * 0.7);
    min-height: auto;
    max-height: auto;
    margin: 0;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    line-height: calc(var(--mm-navbar-size) * 0.7);
    font: inherit;
    font-size: inherit;
}
.mm-searchfield__input input,
.mm-searchfield__input input:focus,
.mm-searchfield__input input:hover {
    background: var(--mm-color-background-highlight);
    color: var(--mm-color-text);
}
.mm-menu[class*="-contrast"] .mm-searchfield__input input {
    border: 1px solid var(--mm-color-border);
}
.mm-searchfield__input input::-ms-clear {
    display: none;
}
.mm-searchfield__btn {
    display: none;
    position: absolute;
    inset-inline-end: 0;
    top: 0;
    bottom: 0;
}
.mm-searchfield--searching .mm-searchfield__btn {
    display: block;
}
.mm-searchfield__cancel {
    display: block;
    position: relative;
    -webkit-margin-end: -100px;
    margin-inline-end: -100px;
    -webkit-padding-start: 5px;
    padding-inline-start: 5px;
    -webkit-padding-end: 20px;
    padding-inline-end: 20px;
    visibility: hidden;
    line-height: var(--mm-navbar-size);
    text-decoration: none;
    -webkit-transition-property: visibility, margin;
    -o-transition-property: visibility, margin;
    transition-property: visibility, margin;
}
.mm-searchfield--cancelable .mm-searchfield__cancel {
    visibility: visible;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
}
.mm-panel--search {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    border: none !important;
}
.mm-panel__splash {
    padding: 20px;
}
.mm-panel--searching .mm-panel__splash {
    display: none;
}
.mm-panel__noresults {
    display: none;
    padding: 40px 20px;
    color: var(--mm-color-text-dimmed);
    text-align: center;
    font-size: 150%;
    line-height: 1.4;
}
.mm-panel--noresults .mm-panel__noresults {
    display: block;
}
.mm-sectionindexer {
    background: inherit;
    text-align: center;
    font-size: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: var(--mm-sectionindexer-size);
    position: absolute;
    top: 0;
    bottom: 0;
    inset-inline-end: calc(-1 * var(--mm-sectionindexer-size));
    z-index: 5;
    -webkit-transition-property: inset-inline-end;
    -o-transition-property: inset-inline-end;
    transition-property: inset-inline-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}
.mm-sectionindexer a {
    color: var(--mm-color-text-dimmed);
    line-height: 1;
    text-decoration: none;
    display: block;
}
.mm-sectionindexer ~ .mm-panel {
    -webkit-padding-end: 0;
    padding-inline-end: 0;
}
.mm-sectionindexer--active {
    right: 0;
}
.mm-sectionindexer--active ~ .mm-panel {
    -webkit-padding-end: var(--mm-sectionindexer-size);
    padding-inline-end: var(--mm-sectionindexer-size);
}
.mm-menu--selected-hover .mm-listitem__btn,
.mm-menu--selected-hover .mm-listitem__text,
.mm-menu--selected-parent .mm-listitem__btn,
.mm-menu--selected-parent .mm-listitem__text {
    -webkit-transition-property: background-color;
    -o-transition-property: background-color;
    transition-property: background-color;
}
@media (hover: hover) {
    .mm-menu--selected-hover .mm-listview:hover > .mm-listitem--selected:not(:hover) > .mm-listitem__text {
        background: 0 0;
    }
    .mm-menu--selected-hover .mm-listitem__btn:hover,
    .mm-menu--selected-hover .mm-listitem__text:hover {
        background: var(--mm-color-background-emphasis);
    }
}
.mm-menu--selected-parent .mm-listitem__btn,
.mm-menu--selected-parent .mm-listitem__text {
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}
@media (hover: hover) {
    .mm-menu--selected-parent .mm-listitem__btn:hover,
    .mm-menu--selected-parent .mm-listitem__text:hover {
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
    }
}
.mm-menu--selected-parent .mm-panel--parent .mm-listitem:not(.mm-listitem--selected-parent) > .mm-listitem__text {
    background: 0 0;
}
.mm-menu--selected-parent .mm-listitem--selected-parent > .mm-listitem__btn,
.mm-menu--selected-parent .mm-listitem--selected-parent > .mm-listitem__text {
    background: var(--mm-color-background-emphasis);
}
.mm-wrapper--sidebar-collapsed .mm-slideout {
    width: calc(100% - var(--mm-sidebar-collapsed-size));
    -webkit-transform: translate3d(var(--mm-sidebar-collapsed-size), 0, 0);
    transform: translate3d(var(--mm-sidebar-collapsed-size), 0, 0);
}
[dir="rtl"] .mm-wrapper--sidebar-collapsed .mm-slideout {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}
.mm-wrapper--sidebar-collapsed:not(.mm-wrapper--opened) .mm-menu--sidebar-collapsed .mm-divider,
.mm-wrapper--sidebar-collapsed:not(.mm-wrapper--opened) .mm-menu--sidebar-collapsed .mm-navbar {
    opacity: 0;
}
.mm-wrapper--sidebar-expanded .mm-menu--sidebar-expanded {
    width: var(--mm-sidebar-expanded-size);
    border-right-width: 1px;
    border-right-style: solid;
}
.mm-wrapper--sidebar-expanded.mm-wrapper--opened {
    overflow: auto;
}
.mm-wrapper--sidebar-expanded.mm-wrapper--opened .mm-wrapper__blocker {
    display: none;
}
.mm-wrapper--sidebar-expanded.mm-wrapper--opened .mm-slideout {
    width: calc(100% - var(--mm-sidebar-expanded-size));
    -webkit-transform: translate3d(var(--mm-sidebar-expanded-size), 0, 0);
    transform: translate3d(var(--mm-sidebar-expanded-size), 0, 0);
}
[dir="rtl"] .mm-wrapper--sidebar-expanded.mm-wrapper--opened .mm-slideout {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}
