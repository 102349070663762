.header_nav {
	padding-top: 7rem !important;
	display:none;
	@media (--md) {
		
		background: rgba(222,222,222, .99) url(../images/bg_noise.gif) repeat center center / 10%;
		&.js_menu_open {
			display: flex;
		}
		position:fixed;
		top:0;
		left:0;
		bottom:0;
		right:0;
		justify-content: center;
		align-items: center;
		padding: 100px 0;
		z-index:999;
		&::before {
			content:'';
			background: transparent url('../images/wm-symbol.svg') no-repeat right center / 80%;
			display: block;
			position: absolute;
			top:0;
			left:50%;
			width: 45vw;
			max-width:550px;
			height: 100vh;
			opacity: .1;
		}
	}
}

/* boton menu  ----------------------------------*/
.menu_btn {
	display:block;
	background: transparent;
	border:none;
	font-size:14px; 
	width: 2.5em;
	height: 2.5em; 
	padding: .5em;
    position: relative;
	z-index: 1000;
	border: none;
	color:currentcolor;
	@media (--md) {
		width: 8em; 
	}
	&:focus {
		outline: 0;
	}
	& .i-menu,
	&::before,
	&::after {
		display: block;
		background:currentcolor;
		width: 1.5em;
		height:2px;
		border-radius:4px;		
		position:relative;
		transition: all 0.3s ease-in-out;
	}
	& .i-menu {
		text-indent: -5000px;
		margin: .35em 0;
		opacity:1;
	}
	&::before,
	&::after {
		content: '';
	}
	&::before {
		bottom: -1.5px;
	}
	&::after {
		top: -1.5px;
	}
	& span {
		transition: all 0.3s ease-in-out;
		display:none; 
		@media (--md) {
			display: block;
			position: absolute;
			top: .5em;
			left: 2.5em;
			text-transform: uppercase;
			font-weight:600;

		}
	}
}




body.desktop,
body.tablet {
	.menu {
		&-list {
			display: grid;
			grid-template-columns: minmax(max-content, 300px) minmax(max-content, 300px);
			list-style: none;
			z-index:999;
		}
		&-item {
			& a {
				display: inline-block;
				font-size:1.75em;
				font-weight:700;
				margin: .125em 0;
				text-decoration: none;
				color: #444;
				transition: all .2s ease;
				&.secundario {
					font-weight:400;
					font-size:1.5em;
				}
				&:hover {
					padding-left: .25rem;
					color: #000;
				}
			}
		}
	}
	.deeper.parent { 
		& > a {
			font-size:2em;
			font-weight:900;
			color: #999;
			display: block;
			margin-bottom:.25em;
		}
	}
	& .header_nav {
		background: rgba(222,222,222, .99) url(../images/bg_noise.gif) repeat center center / 10%;
		&.js_menu_open {
			display: flex;
		}
		position:fixed;
		top:0;
		left:0;
		bottom:0;
		right:0;
		justify-content: center;
		align-items: center;
		padding: 100px 0 200px 0;
		z-index:999;
		&::before {
			content:'';
			background: transparent url('../images/wm-symbol.svg') no-repeat right center / 80%;
			display: block;
			position: absolute;
			top:0;
			left:50%;
			width: 45vw;
			max-width:550px;
			height: 100vh;
			opacity: .15;  
		}

	}


	& .footer_menu {
		&.js_menu_open {
			position: fixed;
			bottom: 0;
			left: 0;
			right:0;
			z-index: 999;
		}
		&-list {
			list-style: none;
			padding: 2rem ;
			margin:0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		&-item {
			@media (--md) {
				&::after{
					content:'|';
				}
				&:last-child::after{
					content:'';
				}
			}
		}
	}
}

.footer_menu {
	&-list {
		list-style: none;
		padding: 2rem ;
		margin:0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	&-item {
		& a {
			color: #333;
			text-decoration: none;
			padding: .5em;
			white-space: nowrap;
			&:hover {
				color: #000;
			}
		}
	}
}







body {
	@media (--md) {
		& .mOpen{
			opacity:1;
		}
		& .mClose{
			opacity:0;
		}
		&.js_menu_open .mOpen{
			opacity:0;
		}
		&.js_menu_open .mClose{
			opacity:1;
		}
		&.js_menu_open .mClose{
			opacity:1;
		}
	}
}
.menu_btn.js_menu_open {
	& .i-menu {
		margin: 0 0 0 3em;
		height: 0;
		opacity: 0;
	}
	&::before{
		transform: rotate(-45deg);
	}
	&::after{
		transform: rotate(45deg);
	}
	&::before,
	&::after{
		transform-origin: 45% 50%;
	}
}
body.js_menu_open {
	overflow: hidden;

	& .footer_menu-list {
		justify-content: flex-start;
		padding: 2rem 0 3rem;
		border-top: solid 1px;
	}
}
  