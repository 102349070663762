@media (--md) {}
@media (--lg) {}
@media (--xl) {}
.entrevista {
    &-item {
        display: flex;
        flex-wrap: wrap;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        border-bottom: solid 1px currentcolor;
        &:last-child {
            border: none;
        }
    }
    &-cover {
        margin:0;
        width:100%;
        @media (--md) {
            width:30%;
        }
    }
    &-txt {
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media (--md) {
            width:70%;
        }
    }
    &-link {
        padding-left: 2em;
        padding-right: 2em;
    }
    &_img-link {
        display:block;
        position: relative;
        &::after {
            font-family: "dhiravamsa-icons";
            content: '\f16a';
            display: flex;
            justify-content:center;
            align-items: center;
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            font-size: 3rem;
            background: rgba(0,0,0,1);
            color:#fff;
            opacity:.15;
            transition: all .3s ease;
        }
        &:hover {
            &::after {
                font-size: 3.5rem;
                opacity:.7;
            }
        }
    }
    &_name {
        font-family: var(--fontPri_A);
        font-weight: 700;
        font-size: 1.25rem;
        @media (--md) {
            font-size: 2rem;
        }
    }
    &-fecha {
        color: var(--colorSec_A);
    }
}