.newsletter {
    &-2 {
        @media (--lg) {
            overflow-x: visible;
        }
        & .swiper{
            max-width: 1200px;
            &::after {
                @media (--md) {
                    content:'';
                    display: block;
                    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); 
                    height: 100%;
                    width: 7rem;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index:999;
                }
            }
            @media (--lg) {
                width: calc(55vw - 25px);
            }
            @media (--xxl) {
                width: calc(60vw - 25px);
            }
        }
    }
    &-arrows {
        display: flex;
        padding:1.5rem 0 0 0;
    }
    &-arrow {
        height:50px;
        background: transparent;
        border: none;
        position: relative;
        transform: translate(0, 0);
        transition: all .2s ease;
        & svg {
            width: auto;
            height: 100%;
        }
        &.prev {
            @media (--lg) {
                margin-right: 2rem;
            }
            &:hover {
                transform: translate(-.5rem, 0);
            }
        }
        &.next {
            &:hover {
                transform: translate(.5rem, 0);
            }
        }
    }
}
.home-new {
    &_item {
        position: relative;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 70% 1rem auto;
        &::before {
            content:' ';
            display: block;
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
            width:100%;
            height:100%;
            position: absolute;
            z-index: 2;
        }
        &-img{
            grid-column:1 / span 1;
            grid-row:1 / span 3;
            width:100%;
            height:100%;
            object-fit: cover;
            position: relative;
            z-index: 1;
        }
        &-title{
            grid-column:1 / span 1;
            grid-row:1 / span 1;
            align-self: end;
            padding: 0 1rem;
            font-size: 1.5rem;
            font-family: 'sagrado_bd';
            font-weight: normal;
            text-shadow: 0 0 .5em #000;
            line-height: 1;
            position: relative;
            z-index: 3;
            & a {
                color: #fff;
                text-decoration: none;
                transition: all .2s ease;
                &:hover {
                    color: var(--colorS);
                }
            }
        }
        &-date{ 
            grid-column:1 / span 1;
            grid-row:2 / span 1;
            padding: 0 1rem;
            font-size: .8rem;
            color: var(--colorP);
            position: relative;
            z-index: 3;
        }
        &-introtext{
            grid-column:1 / span 1;
            grid-row:3 / span 1;
            position: relative;
            padding: .5rem 1rem;
            line-height: 1.1;
            font-weight:100;
            text-shadow: 0 0 .5em #000;
            color: #fff;
            z-index: 3;
        }
        &-readmore{
            position: absolute;
            bottom: 0rem;
            right: 0rem;
            z-index: 5;
            color: #fff;
            font-size:2.5rem;
            text-shadow: 0 0 .5em #000;
            transition: all .2s ease;
            &:hover {
                color: var(--colorS);
                transform: scale(1.1);
            }
        }

    }
}
.f_show {
    opacity:0;
    position: relative;
    top: 2em;
    transition: all .75s ease;
    &.show {
        opacity:1;
        top:0;
    }
}
.newsletter-category {
    padding-bottom:3rem;
    &_item {
        gap: 1rem;
        @media (--lg){
            display:grid;
            grid-template-columns:repeat(5, 1fr);
        }
        &  .item-image {
            grid-column: 1 / span 2;
            grid-row: 1 / span 1;
            gap: 2rem;
            margin: 0;
            display: block;
            position: relative;
            width: 100%;
            z-index:0;
            @media (--lg){
                height: auto;
                padding-bottom: 0;
            }
            &::after {
                /* content: '';
                display: block;
                position: absolute;
                top:0;
                bottom:0;
                right:0;
                width:50%;
                height:100%;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                z-index:2; */
            }
            & img {
                width: 100%;
                z-index:1;
            }
        }
        &-content {
            grid-column:3 / span 3;
            grid-row: 1 / span 1;
            position: relative;
            z-index:3;
        }
        & .news-read_more {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }
}
.newsletter-item {
    @media (--lg){
        margin-top: 1rem;
    }
    &_h {
        text-shadow: 0 0 .25rem rgba(255, 255, 255, .5);
    }
    &_full-image {
        @media (--MD){
            position: relative;
            width:100%;
            z-index: 0;
            padding:0;
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom:0;
                left:0;
                width:100%;
                height:10rem;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); 
            }
        }
    }
    &_text {
        @media (--MD){
            position: relative;
            margin-top: -5rem;
            z-index:1;
        }
    }
    &_headline {
        max-width: 540px;
        @media (--md){max-width: 720px;}
        @media (--lg){max-width: auto;}
        @media (--MD){
            position:relative;
            top: 1px;
            padding-top: 2em;
            padding-left: 1rem;
            padding-right: 1rem;
            margin: 0 auto;
        }

    }
    &_cat-date{
        text-transform: uppercase;
        margin:1em 0;
        color: #999;
        font-size: .9em;

        text-shadow: 0 0 .125em rgba(255, 255, 255, .95);
        & time {
            color: var(--colorP);
        }
    }
    &_body{
        max-width: 540px;
        @media (--md){max-width: 720px;}
        @media (--lg){max-width: auto;}
        @media (--MD){
            padding-left: 1rem;
            padding-right: 1rem;
            
            margin: 0 auto;
        }
    }
}
.img_full {
    position: sticky;
    top: 6rem;
}
.cuadro-cover {
    margin: 0;
    @media (--md){
        width: 100%;
        height:100vw;
        max-height:50vh;
    }
    @media (--md){
        position: sticky;
        top: 1rem;
        height:auto;
        max-height:auto;
    }
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}