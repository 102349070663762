.header {

    padding-top: 1rem;
    position: relative;
    &_container { 
        & > *:first-child{
            min-width: 20%;
        }
        & .user-lang {
            min-width: 20%;
            display:flex;
            justify-content: flex-end;
        }
        color: #fff;
        position: relative;
        display: flex;        
        justify-content: space-between;
        align-items: flex-start;  
        
        &::before, 
        &::after {
            content: '';
            width: calc(50% - 6rem);
            display: block;
            height:1px;
            border-top: solid 1px #fff;
            position:absolute;
            top:3rem;
            
            z-index: 99;
        }
        &::before {left:1rem;}
        &::after {right:1rem;}
        @media (--lg) {
        }
        @media (--xxl) {
        }
    }
    &_brand {
        opacity:1;
        transition: all 0.3s ease-in-out;
        @media (--lg) {
        }
        z-index:999; 
        &-logo { 
            text-indent: -5000px; 
            margin: -.25rem auto 0 auto;
            padding: 0;
            min-width: 85px; 
            width:100%;
            display: block;
            color: #fff;
            font-size: 1.5rem;
            text-decoration: none;
            text-indent: -5000px;
            height: 0;
            line-height: 0;
            padding-bottom: 85px;
            background-repeat: no-repeat;
            background-position:center center;
            background-size: contain;
            @media (--lg) {
                max-width: 15rem;
                background-size: contain;
            }
        }
        &-slogan {}
    }
    &_random-image {
        position: absolute;
        top: 0;
        left:0;
        right:0;
        width:100%;
        min-height:275px;
        text-align:center;
        padding: 6rem 0 1rem 0;
        &::before {
            content:'';
            position: absolute;
            top: 0;
            left:0;
            right:0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,.6);
            mix-blend-mode: multiply;
        }
    } 
    &_h1 {
        font-size:2.25rem;
        color:#fff;
        font-weight: 600;
        font-family: var(--fontSec);
        position:relative; 
        z-index:100;
        @media (--lg) {
            font-size:3rem;
        }
    }
    &_h2 {
        font-size:2.25rem;
        text-align:center;
        @media (--lg) {
            font-size:3rem;
        }
    }
} 
body {
    &.js_menu_open {
        & .header_brand {
            opacity:0;
        }
        & .header_container{
            color: #222;
        }
        &.mobile .header_container{
            color: #fff;
        }
    }
    &.no-logo {
        & .header_container {
            color:#333; 
            &::before, 
            &::after {
                display:none;
            }
        }
    }
}
.user-lang {
    display: flex; 
}
.user-link-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 2.5em;
    padding: 0.5em;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    z-index: 1001;
    color: currentcolor;
    &:hover {
        color: currentcolor;
    }
    & span {
        font-size: 1.2em;
        margin-left: 0.5em;
        font-weight: 600;
        display: none;
    }
    & i {
        font-size: 1.3em;
    }
    @media (--md) {
        width: auto;
        
        & span {
            display: inline-block;
            font-size: 1em;
            margin-left:.5em;
            font-weight:600;
        }
    }
}