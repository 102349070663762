:root {
    --colorPri_A:rgba(11,89,83,1);
    --colorPri_B:rgba(143,198,132,1);
    --colorPri_C:rgba(216,233,209,1);

    --colorSec_A:rgba(107,63,143,1);
    --colorSec_B:rgba(166,148,198,1);
    --colorSec_C:rgba(224,214,234,1);

    --colorTer_A:rgba(238,118,50,1);
    --colorTer_B:rgba(247,172,111,1);
    --colorTer_C:rgba(251,219,200,1);

    --colorCua_A:rgba(50,66,116,1);
    --colorCua_B:rgba(117,186,216,1);
    --colorCua_C:rgba(209,231,241,1);

    --bgSoft:#F2EFE9;
    --fontPri: 'Work Sans', sans-serif;
    --fontSec: 'EB Garamond', serif;

    --bgRP:#B6CFD4;
    --bgRO:#F2EFE9;
    --bgMG:#F2EFE9;

    --bgBTNpri: var(--colorPri_A);
    --bgBTNpriH:  var(--colorPri_B);
}
html{
    /* overflow: hidden; */
}
@media (orientation: landscape) {
    body.mobile::before {
        background: rgba(0,0,0,.95) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNS40LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDE2IDE2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNiAxNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTUuOCwyQzUuOCwyLDUuOCwyLDUuOCwyQzUuNSwyLDUuMywyLjMsNS4zLDIuNWMwLDAuMiwwLjEsMC4zLDAuMywwLjRsMC41LDAuM0M0LjgsMy43LDMuNyw0LjgsMy4zLDYuMQ0KCUwyLjksNS41QzIuOCw1LjQsMi43LDUuMywyLjUsNS4zQzIuMiw1LjMsMiw1LjUsMiw1LjhDMiw1LjksMiw1LjksMi4xLDZsMSwxLjdDMy4yLDcuOSwzLjMsOCwzLjUsOGMwLDAsMCwwLDAsMA0KCWMwLjEsMCwwLjMtMC4xLDAuMy0wLjFsMS42LTAuOWMwLjItMC4xLDAuMy0wLjQsMC4yLTAuN0M1LjYsNiw1LjMsNS45LDUsNmMwLDAsMCwwLDAsMEw0LjIsNi41YzAuMy0xLjIsMS4yLTIuMSwyLjQtMi40TDYuMSw1DQoJQzYsNS4yLDYuMSw1LjUsNi4zLDUuN1M2LjgsNS43LDcsNS41bDEtMS43QzgsMy42LDguMSwzLjUsOCwzLjRjMCwwLDAsMCwwLDBjMCwwLDAsMCwwLTAuMWMwLDAsMCwwLDAsMGMwLDAsMCwwLDAsMA0KCUM3LjksMy4yLDcuOCwzLjEsNy43LDNsLTEuNy0xQzYsMiw1LjksMiw1LjgsMnogTTkuNSwyQzkuMiwyLDksMi4yLDksMi41djhDOSwxMC44LDkuMiwxMSw5LjUsMTFoNWMwLjMsMCwwLjUtMC4yLDAuNS0wLjV2LTgNCglDMTUsMi4yLDE0LjgsMiwxNC41LDJIOS41eiBNMTAsM2g0djdoLTRWM3ogTTIuNSw5QzIuMiw5LDIsOS4yLDIsOS41djVDMiwxNC44LDIuMiwxNSwyLjUsMTVoOGMwLjMsMCwwLjUtMC4yLDAuNS0wLjVWMTJoLTF2Mkgzdi00DQoJaDVWOUgyLjV6Ii8+DQo8L3N2Zz4NCg==") no-repeat 50% 30% / 20%;
        color: #fff;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        position: fixed;
        padding-top: 40vh;
        top: 0;
        left: 0;
        z-index:999;
    }
    body.es.mobile::before {
        content: 'Por favor, gire su dispositivo';
    }
    body.en.mobile::before {
        content: 'Please turn your device';
    }
}
::selection {
    color: #fff !important;
    background:var(--colorTer_A);
}

body {   
    font-family: var(--fontPri);
    background: radial-gradient(#FFFFFF, #E3E3E3) ;
    &::after{
        position: fixed;
        bottom:0;
        right: 0;
        padding: .25em;
        font-size:.5rem;
        display: block;
        border: solid 1px;
        color: salmon;
        content: '···'; 
        @media (--sm) {content: 'SM';}
        @media (--md) {content: 'MD';}
        @media (--lg) {content: 'LG';}
        @media (--xl) {content: 'XL';}
        @media (--xxl){content: 'XXL';}
    } 
    @media (--xxl){
        font-size:1.15em;
    }
}  
h1 {
    font-family: var(--fontSec);
    font-weight: 800;
}
h2 a {
    text-decoration: none;
}
img {
    max-width: 100%;
    height: auto;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 1 !important;
}
.container-main {
    margin-top: 5rem;
    position: relative;
    z-index: 2;
}
.fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.pointer {
    cursor: pointer;
}

.jmodedit {
    width: 2em;
    height: 2em;
    margin-left:-1.5em;
    display: block;
    background:#fff;
    border: solid 1px;
    border-radius: 1em;
    padding:.25em;
    position:absolute;


}
.jmodedit, [role="tooltip"] {
    display: none;
}
.system-unpublished {
    border: solid 2px tomato;
}
.form-group {
    padding: .5rem;
}
.error {
    &-block{
        margin: 4rem 0;
    }
    &-number{
        font-size: calc(2rem + 10vw);
    }
}
.up-btn {
    width: 45px;
    height: 45px;
    right: 10px;
    bottom: 1rem;
    opacity: .9;
    background: var(--colorPri_A);
    color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.25);
    cursor: pointer;
    position: fixed;
    z-index: 2000;
    transition: all .4s ease-in-out;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
  }
  .up-btn.up-btn__hide {
    opacity: 0;
    transform: scale(0);
    transition: all .4s ease-in-out;
  }
  .up-btn:hover {
    opacity: 1;
  }
  .up-btn.up-btn_circle {
    border-radius: 50%;
  }
  .up-btn__img {
    max-width: 100%;
  }
.cc-trigger.cc-trigger-icon svg {
    width: 40px !important;
}
.cc-trigger.cc-trigger-icon.cc-trigger-left {
    left: 10px !important;
}

.convertforms .cf-content-wrap, .convertforms .cf-form-wrap {
    padding: 0 !important;;
} 