.mod-languages__link {
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:14px; 
	width: 2.5em;
	height: 2.5em; 
	padding: .5em;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    z-index: 1001;
    color: currentcolor;
    &:hover {    
        color: currentcolor;
    }
    & i {
        display: none;
    }
    & span {
        font-size: 1.2em;
    }
    @media (--md) {
        width:auto;
        & i {
            display: inline-block;
            font-size: 1.3em;
        }
        & span {
            font-size: 1em;
            margin-left:.5em;
            font-weight:600;
        }
    }

}