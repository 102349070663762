.bloque {
    &-list {
        padding: 0;
        margin: 0;
        list-style: none;
        @media (--lg) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap:1rem;
        }
    }
    &-item {
        display:flex;
        margin-bottom: 1rem;
        @media (--lg) {
            flex-direction: column;
        }
    }
    &-icon {
        max-width: 100px;
        @media (--lg) {
            margin: 0 auto;
        }
    }
    &-link {
        border: solid 15px currentcolor;
        display: flex;
        column-gap: 1rem;
        height: 100%;
        width: 100%;
        background: #fff;
        padding: 1rem 1rem 2rem 1rem;
        text-decoration: none;
        color: #000;
        transition: all .3s ease;
        &:hover {
            transform: scale(1.05,1.05);
            color: #000;
        }
        @media (--lg) {
            flex-direction: column;
            align-items: center;
        }
    }

} 