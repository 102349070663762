@import "bootstrap-css/bootstrap.css"; 
@import "components/root.css"; 
@import "components/layout.css"; 
@import "components/dhiravamsa-icons.css"; 
@import "components/header.css"; 
@import "components/languages.css"; 
@import "components/libros.css"; 
@import "components/retiros.css"; 

@import "components/menu.css"; 
@import "components/mmenu.css"; 
@import "components/entrevistas.css"; 
@import "components/homebio.css"; 
@import "components/modaal.css"; 
@import "components/faqs.css"; 
@import "components/agenda.css";
@import "components/meditaciones.css";
@import "components/glosario.css";
@import "components/infoescuela.css";
@import "components/newsletter.css";
@import "components/escuela.css";


@import "components/swiper.css"; 

@import "components/biografia.css";
@import "components/homenaje.css";
@import "components/innobonos.css";

@import "components/color.css";
@import "components/form.css";

/* @import "includes.css";  */
 
@custom-selector:--heading h1, h2, h3, h4, h5, h6;