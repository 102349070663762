.password-group meter {
    width: 100%;
}
#system-message-container {
    border: solid 1px currentcolor;
} 
.form-control-feedback {
    font-size: .5em;
    margin-left: 1em;
    display: block;
}
.control-group {
    margin-bottom: .5rem;
}

#member-registration {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    @media (--md) {
        grid-template-columns:1fr 1fr;
    }
    & label {
        display: flex;
        align-items: baseline;
    }
    &::before {
        content:'Datos personales';
        font-weight: bold;
        font-size:1.2em;
        grid-row: 1 / span 1;
        @media (--md) {
            grid-column: 1 / span 2;
        }
    }
    &::after {
        content:'Datos de acceso';
        font-weight: bold;
        font-size:1.2em;
        grid-row: 5 / span 1;
        @media (--md) {
            grid-column: 1 / span 2;
            grid-row: 4 / span 1;
        }
    }
}
.field-spacer {
    display: none;
}
.jform {
    &_name {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
        @media (--md) {
        }
    }
    &_username {
        grid-row: 6 / span 1;
        @media (--md) {
            grid-row: 5 / span 1;
        }
    }
    &_password1 {
        @media (--md) {
            grid-column: 2 / span 1;
        }
    }
    &_password2 {
        @media (--md) {
            grid-column: 2 / span 1;
        }
    }
    &_email1 {
        grid-row: 4 / span 1;
        @media (--md) {
            grid-column: 1 / span 2;
            grid-row: 3 / span 1;
        }
    }
    &_profile_phone {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        @media (--md) {
            grid-column: 2 / span 1;
            grid-row: 2 / span 1;
        }
    }
}

[id*="password1]-rules"] {
    display: none;
}
.com-users-registration__register {
    width: 100%;
}
#jform_profile_tos-lbl {
    cursor: pointer;
    text-decoration: underline;
}