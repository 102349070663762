.accordion-button {
    font-weight: 500;
    font-size:1rem;
    cursor: pointer;
    @media (--md) {
        font-size:1.15em;
    }
    &:focus {
        border-color: #eee;
        box-shadow: none;
        &::after {
            background: none;
        }
    }
    &:not(.collapsed) {
        color: #000;
        border-color: #eee;
        box-shadow: none;
        background: transparent;
    }
    & p {
        margin:0;
    }
}
.faqs {
    &_cat-list {
        list-style: none;
        padding: 0;
    }
}
.faq-group-title {
    font-family: var(--fontPri_A);
    font-weight:700;
    font-size: 1.4rem;
}

.faq-group-title + .accordion-item {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}