.retiros-presencial {
    border-color: var(--colorPri_A);
    & .retiros-date_place {
        border-color: var(--colorPri_B);
        background-color: var(--colorPri_C);
    }
    & .retiros-title {
        color: var(--colorPri_A);
    }
    & .retiros-date {
        color: var(--colorPri_B);
    }
    & .retiros-link {
        color: #fff;
        border-color: var(--colorPri_A);
        background-color: var(--colorPri_B);
        &:hover {
            background-color: var(--colorPri_A);
        }
    }
}

.retiros-online {
    border-color: var(--colorCua_A);
    & .retiros-date_place {
        border-color: var(--colorCua_B);
        background-color: var(--colorCua_C);
    }
    & .retiros-title {
        color: var(--colorCua_A);
    }
    & .retiros-date {
        color: var(--colorCua_B);
    }
    & .retiros-link {
        color: #fff;
        border-color: var(--colorCua_A);
        background-color: var(--colorCua_B);
        &:hover {
            background-color: var(--colorCua_A);
        }
    }
}


.retiro-presencial {
    & .retiro-resumen {
        border-color: var(--colorPri_A);
    }
    & .retiro-dias {
        background-color: var(--colorPri_C);
        color: var(--colorPri_A);
    }
    & .retiro-aside {
        background-color: var(--colorPri_C);
        color: var(--colorPri_A);
    }
}

.retiro-online {
    & .retiro-resumen {
        border-color: var(--colorCua_A);
    }
    & .retiro-dias {
        background-color: var(--colorCua_C);
        color: var(--colorCua_A);
    }
    & .retiro-aside {
        background-color: var(--colorCua_C);
        color: var(--colorCua_A);
    }
}

.meditaciones-online .retiros-date_place {
    background-color: var(--med_on_b);
}