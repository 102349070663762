
.bio {
  &_block {
    margin:2rem 0;
  } 
	&_year { 
		display: grid;
		grid-template-columns: 2fr 10fr;
		gap: 0 1rem;
		@media (--md){
			grid-template-columns: 1fr 3fr;
			padding: 0 2rem;
			gap: 0 2rem;
		}
		@media (--lg){
			grid-template-columns: 1fr 4fr; 
		}
		&-sticky {
			border-right: solid 1px var(--colorPri_A);
			text-align: right;	
		}
		&-num {
			position: sticky;
			top: 1.5rem;
			font-weight:900;
			color: var(--colorPri_A);
			color: transparent;
			-webkit-text-stroke: 1px var(--colorPri_A);
			-webkit-text-fill-color: transparent;
			backface-visibility: hidden;
			padding: .75rem .5rem 3rem .5rem; 
			@media (--md){
				font-size: 2.5rem;
				padding: 2rem 1.5rem; 
			}
			@media (--xl){
				font-size: 3rem;
			}
			@media (--xxl){
				font-size: 3.5rem;
			}
			&::before {
				font-size: .7rem;
				content: '';
				display: block;
				border-radius:1em;
        		border: solid 1px var(--colorPri_A);
				height: 1em;
				width: 1em;
				background: #fff;
				position: absolute;
				top: 1.15rem;
				right: -.35rem;
				@media (--md){
					font-size: 1rem;
					right: -.5rem;
					top:3rem;
				}
			}
			&::after {
				content: '';
				display: block;
				position: absolute;
				top:1rem;
				right:calc( -1.5rem - 1px );
				width: 1rem;
				height: 1rem;
				transform: rotate(45deg);
				/* border-bottom: solid 1px #ccc;
				border-left: solid 1px #ccc; */
				background: #fff;
				z-index: 100;
				@media (--md){
					top:3rem;
					right:calc( -2.5rem - 1px );
				}
			}
		}
		&-txt {
			font-family: var(--inter);
			padding: 1.5rem .75rem 1.5rem;
			/* border: solid 1px #ccc;  */
			margin-bottom: 2rem;
			@media (--md){
				padding: 1.5rem;
			}
		}
	}
}
.slick-prev::before,
.slick-next::before {
    font-family: 'dhiravamsa-icons'!important;
    font-size: 20px;
}

.slick-prev::before {
    content: '\f053'!important; 
}
.slick-next::before {
	content: '\f054'!important;
}