.agenda {
    display: flex;
    flex-direction: column;
    @media (--md){
        gap: 1rem;
    }
    &-filter {
        display: flex;
        padding: 1rem;
        border: solid 1px #ccc;
        margin-bottom: 1rem;
        position: sticky;
        top: 0;
        background: #fff;
        &-tag {
            font-size: 1.5em;
        }
        &-list {
            padding: 0 0 0 1rem;
            margin: 0;
            width:100%;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: .75rem;
            @media (--md){
                font-size: 1rem;
            }
        }
        &-item {
            margin: .5em;
            display: flex;
            align-items: center;
            cursor: pointer;
            &::before {
                content:'';
                display: inline-block;
                width: 1.25em;
                height: 1.25em;
                border: solid 3px #ccc;
                border-radius:1em;
                margin-right: .25em;
                margin-top: -2px;
                @media (--md) {
                    border: solid 4px #ccc;
                }
            }
        }
    } 
    &-item {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:.25rem;
        border: solid 1px currentcolor;
        background: #fefefe;
        padding: 0 1rem 1rem 1rem;
        margin-top: 80px;
        @media (--md){
            gap:1rem;
            flex-direction: row;
            padding: .75rem 1rem;
            margin-top: 1.5rem;
            align-items: center;
            flex-wrap: nowrap;
        }
        &_fecha {
            order:1;
            text-align: center;
            display: flex;
            @media (--md){
                display: block;
                order:0;
                width:75px;
                font-size: .7rem;
            }
            @media (--lg){
                width:100px;
            }
            & p {
                margin:0;
            }
            & strong {
                font-weight: 900;
                @media (--md){
                    font-size: 2.5em;
                    display: block;
                } 
            }
            & span {
                font-weight: 700;
                @media (--md){
                    font-size: 1.5em;
                    display: block;
                } 
            }
        }
        &_cover {
            order:0;
            width:135px;
            height:135px;
            border-radius: 150px;
            overflow: hidden;
            border: solid 3px currentcolor;
            margin: -75px auto 0 auto;
            padding: 1rem;
            @media (--md){
                order:1;
                margin: -3rem 0;
            }
            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &_info {
            order:2;
            @media (--md){
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(100% - (210px + 2rem));
                padding-left: 1rem;
            }
            & p {
                @media (--md){
                    margin: 0;
                }
            }
        }
        &_txt {
            
        }
        &.hide {
            display: none;
        }
        &.show {
            animation: fadeIn 0.4s ease-in;
        }
    }
    
}
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
:root {
    --conf_a:rgb(246, 112, 49);
    --conf_b:rgb(253, 221, 200);
    --conf_c:rgb(255, 179, 67);

    --med_pre_a:rgb(81, 30, 135);
    --med_pre_b:rgb(223, 214, 233);
    --med_pre_c:rgb(152, 120, 185);

    --med_on_a:rgb(81, 30, 135);
    --med_on_b:rgb(253, 225, 240);
    --med_on_c:rgb(251, 165, 210);

    --ret_pre_a:rgb(14, 60, 57);
    --ret_pre_b:rgb(215, 238, 210);
    --ret_pre_c:rgb(127, 200, 108);

    --ret_on_a:rgb(50, 66, 116);
    --ret_on_b:rgb(209, 231, 241);
    --ret_on_c:rgb(119, 185, 217);

}

.retiros-presenciales {
    color: var(--ret_pre_a);
    & .agenda-item_cover {
        background-color: var(--ret_pre_b);
        color: var(--ret_pre_a);
    }
    & .agenda-item_link{
        background-color: var(--ret_pre_a);
        color: #fff;
        &:hover {
            background-color: var(--ret_pre_c);
        }
    }
}
.retiros-online {
    color: var(--ret_on_a);
    & .agenda-item_cover {
        background-color: var(--ret_on_b);
        color: var(--ret_on_a);
    }
    & .agenda-item_link{
        background-color: var(--ret_on_a);
        color: #fff;
        &:hover {
            background-color: var(--ret_on_c);
        }
    }
}
.meditaciones-presenciales {
    color: var(--med_pre_a);
    & .agenda-item_cover {
        background-color: var(--med_pre_b);
        color: var(--med_pre_a);
    }
    & .agenda-item_link{
        background-color: var(--med_pre_a);
        color: #fff;
        &:hover {
            background-color: var(--med_pre_c);
        }
    }
}
.meditaciones-online {
    color: var(--med_on_a);
    & .agenda-item_cover {
        background-color: var(--med_on_b);
        color: var(--med_on_a);
    }
    & .agenda-item_link{
        background-color: var(--med_on_a);
        color: #fff;
        &:hover {
            background-color: var(--med_on_c);
        }
    }
}
.conferencias {}


.agenda-filter-item.conferencias::before {
    border-color: var(--conf_a);
    background-color: var(--conf_b);
}
.agenda-filter-item.retiros-presenciales::before {
    border-color: var(--ret_pre_a);
    background-color: var(--ret_pre_b);
}
.agenda-filter-item.retiros-online::before {
    border-color: var(--ret_on_a);
    background-color: var(--ret_on_b);
}
.agenda-filter-item.meditaciones-presenciales::before {
    border-color: var(--med_pre_a);
    background-color: var(--med_pre_b);
}
.agenda-filter-item.meditaciones-online::before {
    border-color: var(--med_on_a);
    background-color: var(--med_on_b);
}