body {
    @media (--md) {}
    @media (--lg) {    }
    @media (--xl) {}
}
main {
    min-height:calc(100vh - 530px);
    position: relative;
    z-index:10;
}
.component {
    background:#fff;
    padding: 1rem 1rem 3rem; 
    @media (--md) {
        padding: 1rem 2rem 3rem; 
    }
    @media (--lg) {
        
    }
    @media (--xl) {
        padding: 1rem 3rem 4rem; 
    }
}
footer.footer {
    background: #ebe6de;
}