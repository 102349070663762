.glosario {
    &-grupo {
        border-bottom: solid 1px currentcolor;
        padding: 1.5rem 0;
        margin: 0; 
        &:last-child {
            border-bottom: none;
        }
    }
    &-letra {
        font-size: 1.75em;
        & span {
            position: sticky;
            top: 0;
        }
    }
    &-terminos {

    }
    &-termino {
        padding-top: .5em;
        padding-bottom: .5em;
        &.gris{
            background: #eee;
        }
    }
    &-descripcion {
        padding-bottom: .5em;
        @media (--md) {
            padding-top: .5em;
        }
        &.gris{
            background: #eee;
        }
        & p {
            margin: 0;
        }
    }
} 