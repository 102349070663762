.homebio {
    display: grid;
    grid-template-columns:1fr;
    grid-template-rows:auto auto 4rem auto;
    padding:0;
    background: transparent url('../images/wm-dhiravamsa.svg') no-repeat -200px top;
    @media (--md) {
        grid-template-columns: 15rem 1fr 5rem;
        grid-template-rows:auto 5rem auto;
        background: transparent url('../images/wm-dhiravamsa.svg') no-repeat top center / 50%;
    }
    @media (--lg) {
        grid-template-columns: 1fr 1fr 10rem;
        grid-template-rows:auto 5rem auto;
    } 
    &_header {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        width: calc(100% - 2rem);
        margin: 0 auto 2rem;
        z-index:6;
        @media (--md) {
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;
            align-self: center;
            margin-bottom: 0;
        }
        & h1 {
            font-size:calc(3.5rem + 1vw);
            color: var(--colorPri);
            line-height: 1;
            text-shadow: 0 0 .125em rgba(255,255,255,.7);
            @media (--md) {font-size:calc(4rem + 3vw);}
            & small {
                display:block;
                font-weight:500;
                font-size:.75em;
                line-height: 1;
            }
            & strong {
                
            }
        }
        & span {
            font-size:1.5em;
            font-weight:bold;
            color: var(colorPri);
        }
    }
    &_portrait {
        grid-column: 1 / span 1;
        grid-row: 1 / span 3;
        position: relative;
        z-index:2;
        @media (--md) {
            grid-column: 2 / span 2;
            grid-row: 1 / span 2;
            z-index:5;
        }
        @media (--lg) {
        }
        &::after {
            /*content:'';*/
            display: block;
            width:100%;
            height:100%;
            position: absolute;
            bottom:0;
            left:0;
            background: rgba(255,102,51,.1);
            background: linear-gradient(0deg, rgba(255,119,61,0.9) 0%, rgba(255,119,61,0) 100%);
            mix-blend-mode:hard-light;
        }
    }
    &_img {
        width:100%;
        @media (--md) {
        }
        @media (--lg) {
            width:100%;
        }
    }
    &_intro {
        grid-column: 1 / span 1;
        grid-row: 3 / span 2;
        width: calc(100% - 2rem);
        margin: 0 auto;
        padding: 1rem 1rem;
        background: #fff;
        position: relative;
        z-index:3;
        @media (--md) {
            grid-column: 1 / span 3;
            grid-row: 2 / span 2;
            padding:3rem;
        }
        @media (--lg) {
            padding:4rem;
        }
    }
    &_title {
        font-size: 2.5rem;
    }
}