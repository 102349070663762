.mod_homenaje {
    &-home {
        background-color:#fafafa;
        position: relative;
        &-block {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-template-rows: 3rem auto auto auto;
        }

    }
    &-fondo {
        grid-column: 1 / span 4;
        grid-rows:1 / span 3;
    }
    &-img {
        grid-column: 4 / span 4;
    }
    &-intro {
        grid-column: 1 / span 7;
        grid-row:7 / span 1;
        color:#FF6633;
        margin-top: 2rem;

        @media (--lg){
            grid-column: 5 / span 3;
            grid-row:3 / span 1;
            font-size: 1.5rem;
            margin-top: 0;
        }
    }
} 