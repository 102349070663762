.retiro {
    &-sticky {
        position: sticky;
        bottom: 0;

        @media (--lg) {
            position: relative;
            bottom: auto;
        }
        &-lg {
            position: sticky;
            top:2rem;
            padding: 1rem;
        }
    }
    &-aside {
        background: #ccc;
    }
    &-sub {
        font-family: var(--fontPri_A);
        font-weight:600;
        font-size:1.25em;
    }
    &-dias {
        font-size:1.25em;
        background: var(--bgRP);
        padding: .5rem 0;
        margin: 0 0 .75rem 0;
        & p {
            margin:0;
        }
    }
    &-llegada {
        border-bottom: solid 1px currentcolor;
        width: calc(100% - 2rem);
        margin: 0 auto;
        @media (--md) {
            width: 50%;
            border-bottom: none;
            border-right: solid 1px currentcolor;
        }
    }
    &-salida {
        border-top: solid 1px currentcolor;
        width: calc(100% - 2rem);
        margin: 0 auto;
        @media (--md) {
            width: 50%;
            border-top: none;
            border-left: solid 1px currentcolor;
        }
    }
    &-h3 {
        font-family: var(--fontPri);
        font-weight:600;
        font-size:1.15em;
        text-transform: uppercase;
        margin:2rem 0 .5rem 0;
    }
    &-resumen {
        border: solid 3px currentcolor;
        padding: 1rem;
        text-align:center;
    }
    &-icon {
        display: flex;
        justify-content: center;
        padding: 0;
        @media (--md) {
            padding: 0 1rem;
        }
        &-img {
            max-width: 70px;
            height: auto;
            @media (--md) {
                max-width: 100px;
            }
        }
    }
}

.retiros {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem 1rem;
    margin-top: 2rem;
    @media (--lg) {
        grid-template-columns: repeat(2, 1fr);
        gap:3rem 2rem;
    }
    &-item {
        &-block {
            border: solid 3px currentcolor;
            border-radius:.5rem;
            display: grid;
            border: solid 3px #ccc;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            @media (--md) {
                grid-template-columns: 1fr 3fr;
                grid-template-rows: 1fr;
            }
        }
    }    
    &-date_place{
        background: #eee;
        border: solid 1px #ccc;
        padding: 1rem;
        grid-row: 2 / span 1;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto auto;
        gap: 0 1rem;
        margin: 0 -.5rem 1rem -.5rem ;
        @media (--md) {
            grid-row: 1 / span 1;
            margin: -1rem 0 -1rem 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center; 
            gap: 0;
        }
    }
    &-txt {
        padding: 1rem;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &-date{ 
        text-align: center;
        font-weight:700;
        padding: .125em .5em;
        color: currentcolor;
        border: solid 2px currentcolor;
    }
    &-n-dias {
        text-transform:uppercase;
        display: inline-block;
        margin-right: 1em;
    }
    &-place {
        & strong {
            font-size: 1.25em;
            line-height:1;
            font-weight: 900;
        }
        & p {
            margin: 0;
        }
    }
    &-icon {
        grid-row: 1 / span 2;
        max-width: 125px;
        justify-self: center;
        @media (--md) {
            max-width:125px;
        }
    }
    &-cta {

    }

    &-title {
        font-size:2.25rem;
        line-height:.9;
    }
    &-subtitle {

    }
    &-intro {

    }
    &-link {
        text-align: left;
        padding: 1em 2.25em 1em 1.25em;
        line-height:1;
        position: relative;
        white-space: nowrap;
        & > i {
            position: absolute;
            right: .5em;
            top: 50%;
            margin-top:-.5em;
        }
    } 
}