@font-face {
  font-family: 'dhiravamsa-icons';
  src: url('../font/dhiravamsa-icons.eot?12818735');
  src: url('../font/dhiravamsa-icons.eot?12818735#iefix') format('embedded-opentype'),
       url('../font/dhiravamsa-icons.woff2?12818735') format('woff2'),
       url('../font/dhiravamsa-icons.woff?12818735') format('woff'),
       url('../font/dhiravamsa-icons.ttf?12818735') format('truetype'),
       url('../font/dhiravamsa-icons.svg?12818735#dhiravamsa-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'dhiravamsa-icons';
    src: url('../font/dhiravamsa-icons.svg?12818735#dhiravamsa-icons') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "dhiravamsa-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-block:before { content: '\e800'; } /* '' */
.icon-login:before { content: '\e805'; } /* '' */
.icon-logout:before { content: '\e806'; } /* '' */
.icon-eye:before { content: '\e807'; } /* '' */
.icon-lock:before { content: '\e809'; } /* '' */
.icon-lock-open:before { content: '\e80a'; } /* '' */
.icon-attention:before { content: '\e80c'; } /* '' */
.icon-attention-circled:before { content: '\e80d'; } /* '' */
.icon-location:before { content: '\e80e'; } /* '' */
.icon-ok:before { content: '\e810'; } /* '' */
.icon-ok-circled:before { content: '\e811'; } /* '' */
.icon-ok-circled2:before { content: '\e812'; } /* '' */
.icon-cancel-circled2:before { content: '\e815'; } /* '' */
.icon-minus:before { content: '\e818'; } /* '' */
.icon-minus-circled:before { content: '\e819'; } /* '' */
.icon-help-circled:before { content: '\e81a'; } /* '' */
.icon-info-circled:before { content: '\e81b'; } /* '' */
.icon-link:before { content: '\e81d'; } /* '' */
.icon-export:before { content: '\e81e'; } /* '' */
.icon-eye-off:before { content: '\e81f'; } /* '' */
.icon-phone:before { content: '\e820'; } /* '' */
.icon-check:before { content: '\e828'; } /* '' */
.icon-long-arrow-down:before { content: '\e829'; } /* '' */
.icon-long-arrow-up:before { content: '\e82a'; } /* '' */
.icon-long-arrow-left:before { content: '\e82b'; } /* '' */
.icon-long-arrow-right:before { content: '\e82c'; } /* '' */
.icon-ico-lang:before { content: '\ee70'; } /* '' */
.icon-user:before { content: '\f007'; } /* '' */
.icon-check-1:before { content: '\f00c'; } /* '' */
.icon-times:before { content: '\f00d'; } /* '' */
.icon-cog:before { content: '\f013'; } /* '' */
.icon-download:before { content: '\f019'; } /* '' */
.icon-redo:before { content: '\f01e'; } /* '' */
.icon-sync:before { content: '\f021'; } /* '' */
.icon-list-alt:before { content: '\f022'; } /* '' */
.icon-lock-1:before { content: '\f023'; } /* '' */
.icon-pencil:before { content: '\f040'; } /* '' */
.icon-chevron-left:before { content: '\f053'; } /* '' */
.icon-chevron-right:before { content: '\f054'; } /* '' */
.icon-plus-circle:before { content: '\f055'; } /* '' */
.icon-minus-circle:before { content: '\f056'; } /* '' */
.icon-times-circle:before { content: '\f057'; } /* '' */
.icon-check-circle:before { content: '\f058'; } /* '' */
.icon-question-circle:before { content: '\f059'; } /* '' */
.icon-info-circle:before { content: '\f05a'; } /* '' */
.icon-instagram-circled:before { content: '\f05e'; } /* '' */
.icon-arrow-left:before { content: '\f060'; } /* '' */
.icon-arrow-right:before { content: '\f061'; } /* '' */
.icon-arrow-up:before { content: '\f062'; } /* '' */
.icon-arrow-down:before { content: '\f063'; } /* '' */
.icon-exclamation-circle:before { content: '\f06a'; } /* '' */
.icon-exclamation-triangle:before { content: '\f071'; } /* '' */
.icon-calendar-alt:before { content: '\f073'; } /* '' */
.icon-key:before { content: '\f084'; } /* '' */
.icon-sign-out:before { content: '\f08b'; } /* '' */
.icon-sign-in:before { content: '\f090'; } /* '' */
.icon-phone-1:before { content: '\f095'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-credit-card:before { content: '\f09d'; } /* '' */
.icon-arrow-circle-left:before { content: '\f0a8'; } /* '' */
.icon-arrow-circle-right:before { content: '\f0a9'; } /* '' */
.icon-arrow-circle-up:before { content: '\f0aa'; } /* '' */
.icon-arrow-circle-down:before { content: '\f0ab'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-users:before { content: '\f0c0'; } /* '' */
.icon-paperclip:before { content: '\f0c6'; } /* '' */
.icon-save:before { content: '\f0c7'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-flag-checkered:before { content: '\f11e'; } /* '' */
.icon-help:before { content: '\f128'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-down:before { content: '\f175'; } /* '' */
.icon-up:before { content: '\f176'; } /* '' */
.icon-left:before { content: '\f177'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-university:before { content: '\f19c'; } /* '' */
.icon-graduation-cap:before { content: '\f19d'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-bed:before { content: '\f236'; } /* '' */
.icon-phone-volume:before { content: '\f2a0'; } /* '' */
.icon-images:before { content: '\f302'; } /* '' */
.icon-vimeo-circled:before { content: '\f307'; } /* '' */
.icon-facebook-circled:before { content: '\f30d'; } /* '' */
.icon-alarm-clock:before { content: '\f34e'; } /* '' */
.icon-lock-open-1:before { content: '\f3c1'; } /* '' */
.icon-comment-alt-exclamation:before { content: '\f4a5'; } /* '' */
.icon-dove:before { content: '\f4ba'; } /* '' */
.icon-hands:before { content: '\f4c2'; } /* '' */
.icon-hands-heart:before { content: '\f4c3'; } /* '' */
.icon-hands-helping:before { content: '\f4c4'; } /* '' */
.icon-handshake-alt:before { content: '\f4c6'; } /* '' */
.icon-user-graduate:before { content: '\f501'; } /* '' */
.icon-fingerprint:before { content: '\f577'; } /* '' */
.icon-book-alt:before { content: '\f5d9'; } /* '' */
.icon-books:before { content: '\f5db'; } /* '' */
.icon-map-marker-check:before { content: '\f606'; } /* '' */
.icon-hamsa:before { content: '\f665'; } /* '' */
.icon-landmark:before { content: '\f66f'; } /* '' */
.icon-praying-hands:before { content: '\f684'; } /* '' */
.icon-head-side:before { content: '\f6e9'; } /* '' */
.icon-ballot:before { content: '\f732'; } /* '' */
.icon-clipboard-list-check:before { content: '\f737'; } /* '' */
.icon-home-alt:before { content: '\f80a'; } /* '' */
.icon-phone-alt:before { content: '\f879'; } /* '' */
.icon-phone-laptop:before { content: '\f87a'; } /* '' */
