.books {
    @media (--md) {}
    @media (--lg) {}
    @media (--xl) {}
}
.book {
    position: relative;
    display:grid;
    grid-template-columns: 2rem 1fr 2rem;
    grid-template-rows: 3rem 1fr auto; 
    margin-bottom:2rem;
    @media (--md) {
      grid-template-columns: 2rem 3fr 1rem 4fr;
      grid-template-rows: 2rem auto auto 4rem 2rem; 
    }
    &::before {
        content:'';
        background: var(--bgSoft);
        display: block;
        height: 100%;
        width: 100%;
        grid-column: 1 / span 4;
        grid-row: 2 / span 6;
        @media (--md) {
          grid-column: 1 / span 5;
          grid-row: 2 / span 3;
        }
    }
    &-cover {
      margin:0;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;
      @media (--md) {
        grid-column: 2 / span 1;
        grid-row: 1 / span 5;
      }
        &_img {
            border: solid 1px #eee;
        }
    }
    &-header { 
        grid-column: 2 / span 1;
        grid-row: 3 / span 1;
        @media (--md) {
          grid-column: 4 / span 1;
          grid-row: 2 / span 1;
      }
    }
    &-title {
        font-size:1.5rem;
        font-family: 'Work Sans', sans-serif;
        margin: 1rem 1rem 0 0;
    }
    &-subtitle {
      font-size:1.25rem;
    }
    &-publisher {
      color: var(--colorPri);
      font-size:.8em;
      white-space: nowrap;
      transform-origin: 0;
      transform: rotate(-90deg);
      position: absolute;
      left: 1rem;
      bottom: 0;
      margin:0 0 1rem 0; 
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      @media (--md) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 3;
      }
    }
    &-info {
      grid-column: 2 / span 1;
      grid-row: 5 / span 1;
      @media (--md) {
          grid-column: 4 / span 1;
          grid-row: 3 / span 1;
      }
    }
    &-link {
      line-height:2rem;
      font-size:.75em;
      font-family: 'Work Sans', sans-serif;       
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 1rem 1rem 0;
      grid-column: 2 / span 1;
      grid-row: 6 / span 1;
      @media (--md) {
        grid-column: 4 / span 1;
        grid-row: 4 / span 1;
      }
    }
}




  /* Filterable Gallery */
  .filter_tabs {
    list-style: none;
    margin:0 0 1rem 0;
    padding: 1rem 0;
    background-color: inherit;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: .5rem;   
  }
  .filter_tabs li {
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 2rem;
    border: solid 1px #ccc;
  }
  .filter_tabs li.active,
  .filter_tabs li:hover {
    color: #000;
  }
  .gallery_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-top: 4rem;
  }
  .book {
    overflow: hidden;
  }
  /* when the book has 'show' class */ 
  .book.show {
    animation: fadeIn 0.4s ease-in;
  }
  @keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  /* when the book has 'hide' class */
  .book.hide {
    display: none;
  }


.mod_libros {
  height: auto;
  &-link {
    
  }
}