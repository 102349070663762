.home-escuela {
    &-txt {
        background:var(--colorPri_C);
        color:var(--colorPri_A);
        margin-top: -3rem;
        padding-top:5rem;
    }
    & figcaption {
        text-align: center;
        margin-top:1rem;
    }
    &-esc {
        text-align: center;
        color:var(--colorPri_A);
        & h2 {
            font-size: 2.5em;
        }
    }
    &-msj {
        color:var(--colorPri_A);
        & h2 {
            font-size: 2.5em;
        }
        & h4 {
            font-size: 1.5em;
        }
    }
}